<template>
  <div class="main index" v-if="!isLoading">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(processFormRegister)">
        <div>
          <div class="columns is-multiline">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Settings</b></h1>
            </div>
            <div class="column is-10">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Setting</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `user` }">
                      <h3 class="is-size-6 breadcrumb-item">User</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-6 breadcrumb-item"
                      v-if="$route.name != 'editUser'"
                    >
                      Change Password
                    </h3>
                    <h3 class="is-size-6 breadcrumb-item" v-else>EDIT</h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column is-2">
              <b-field grouped position="is-right">
                <b-button type="is-hcc" native-type="submit" icon-left="save"
                  >Save</b-button
                >
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <div class="columns is-12">
                  <div class="column is-2">
                    <b>Old Password</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      vid="pConf1"
                      name="password"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-input
                        type="password"
                        v-model="user.oldPassword"
                        password-reveal
                      ></b-input>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="columns is-12">
                  <div class="column is-2">
                    <b>New Password</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      vid="pConf1"
                      name="password"
                      rules="required|password:pConf2"
                      v-slot="{ errors }"
                    >
                      <b-input
                        type="password"
                        v-model="user.password"
                        password-reveal
                      ></b-input>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="columns is-12">
                  <div class="column is-2">
                    <b>Password Confirmation</b>
                    <b-tooltip type="is-danger" label="This field is required">
                      <span class="required">*</span>
                    </b-tooltip>
                  </div>
                  <div class="column is-10">
                    <ValidationProvider
                      vid="pConf2"
                      name="confirm password"
                      rules="required|password:pConf1"
                      v-slot="{ errors }"
                    >
                      <b-input
                        type="password"
                        v-model="user.confirmation"
                        password-reveal
                      ></b-input>
                      <span class="required">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      user: {
        id: null,
        oldPassword: null,
        password: null,
        confirmation: null
      }
    };
  },
  methods: {
    processFormRegister() {
      this.user.id = this.$route.params.userId;
      this.$store
        .dispatch("updatePassword", this.user)
        .then(() => {
          this.success("Change password success");
        })
        .catch(() => {
          this.danger("failed to change password");
        });
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>

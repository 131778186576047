var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{staticClass:"main index"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.processFormRegister)}}},[_c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('h1',{staticClass:"is-size-3"},[_c('b',[_vm._v("Settings")])])]),_c('div',{staticClass:"column is-10"},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('h3',{staticClass:"is-size-6"},[_vm._v("Setting")])]),_c('li',[_c('router-link',{attrs:{"to":{ name: "user" }}},[_c('h3',{staticClass:"is-size-6 breadcrumb-item"},[_vm._v("User")])])],1),_c('li',[(_vm.$route.name != 'editUser')?_c('h3',{staticClass:"is-size-6 breadcrumb-item"},[_vm._v(" Change Password ")]):_c('h3',{staticClass:"is-size-6 breadcrumb-item"},[_vm._v("EDIT")])])])])]),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[_c('b-button',{attrs:{"type":"is-hcc","native-type":"submit","icon-left":"save"}},[_vm._v("Save")])],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Old Password")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"pConf1","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.user.oldPassword),callback:function ($$v) {_vm.$set(_vm.user, "oldPassword", $$v)},expression:"user.oldPassword"}}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("New Password")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"pConf1","name":"password","rules":"required|password:pConf2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"columns is-12"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Password Confirmation")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"pConf2","name":"confirm password","rules":"required|password:pConf1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.user.confirmation),callback:function ($$v) {_vm.$set(_vm.user, "confirmation", $$v)},expression:"user.confirmation"}}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])])])])]}}],null,false,975703506)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }